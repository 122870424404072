contact = {};

contact.submitForm = function () {
    let data = {};
    data.firstName = $('#f_name').val();
    data.lastName = $('#l_name').val();
    data.email = $('#email').val();
    data.message = $('#message').val();
    data.privacy_checkbox = $('#privacy_checkbox').val();

    $.ajax({
        method: "POST",
        data: data,
        url: "/contact/submit",
        success: function (response) {
            $('#contactForm').removeClass('was-validated');
            $('.alert-success').addClass('d-none');
            $('.alert-danger').addClass('d-none');

            if (response.status === 200) {
                $('.alert-success').removeClass('d-none');
                $('.alert-success span').text(response.message);
            }else {
                $('#contactForm').addClass('was-validated');
            }
        },
        error: function (response) {
            $('.alert-danger').removeClass('d-none');
            $('.alert-danger span').text(response.message);
        }
    });
};
