// Navbar menu enable hover dropdowns
document.addEventListener("DOMContentLoaded", function () {
        navbarHover(true);
});

// window.addEventListener("resize", function () {
//     if (window.innerWidth > 992) {
//         navbarHover(true);
//     }else {
//         navbarHover(false);
//     }
// });

// On hover show dropdown
let onHover = function () {

    let el_link = this.querySelector('a[data-bs-toggle]');
    // let el_main_menu = document.getElementById('main-menu');
    // el_main_menu.classList.add('bg-white');

    if (el_link != null) {
        let nextEl = el_link.nextElementSibling;
        el_link.classList.add('show');

        if(nextEl !== null) {
            nextEl.classList.add('show');
        }
    }

};

// On mouse leave hide dropdown
let onMouseLeave = function () {
    let el_link = this.querySelector('a[data-bs-toggle]');
    // let el_main_menu = document.getElementById('main-menu');
    // el_main_menu.classList.remove('bg-white');


    if (el_link != null) {
        let nextEl = el_link.nextElementSibling;
        el_link.classList.remove('show');

        if(nextEl !== null) {
            nextEl.classList.remove('show');
        }
    }


}

function navbarHover(x)
{
    let elements = document.querySelectorAll('.navbar .nav-item.dropdown');

    if (x) {
        elements.forEach(function (everyitem) {
            everyitem.addEventListener('mouseover', onHover, true);
            everyitem.addEventListener('mouseleave', onMouseLeave, true);
        });
    }else {
        elements.forEach(function (everyitem) {
            everyitem.removeEventListener('mouseover', onHover, true);
            everyitem.removeEventListener('mouseleave', onMouseLeave, true);
        });
    }
}
