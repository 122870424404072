_token = $('meta[name="csrf-token"]').attr('content');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': _token
    }
});
// Select
const selects = document.querySelectorAll('.choices');
selects.forEach((e) => {
    const choices = new Choices(e, {
        allowHTML: false,
    });
});

// Datepicker
const flatPicker = flatpickr('.flatpickr', {});
const flatPickerRange = flatpickr('.flatpickr-range', {
    mode: 'range',
    showMonths: 2
});

// Live clock
window.dateToText = function(date) {
    let hours = date.getHours()
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    // var seconds = date.getSeconds();
    if (minutes < 10) minutes = '0'+minutes;
    //if  seconds < 10) seconds = '0'+seconds;
    if (hours < 10) hours = '0'+hours;
    return hours + ":" + minutes + ":" + seconds;
}
window.updateClocks = function() {
    for (let i = 0; i < window.arrClocks.length; i++) {
        let clock = window.arrClocks[i];
        let offset = window.arrOffsets[i];
        clock.innerHTML = dateToText(new Date(new Date().getTime()+offset));
    }
}
window.startClocks = function() {
    clockElements = document.getElementsByClassName('clock');
    window.arrClocks = []
    window.arrOffsets = [];
    var j = 0;
    for(let i = 0; i < clockElements.length; i++) {
        el = clockElements[i];
        timezone = parseInt(el.getAttribute('timezone'));
        if (!isNaN(timezone)) {
            let tzDifference = timezone * 60 + (new Date()).getTimezoneOffset();
            let offset = tzDifference * 60 * 1000;
            window.arrClocks.push(el);
            window.arrOffsets.push(offset);
        }
    }
    updateClocks();
    clockID = setInterval(updateClocks, 1000);
}

// Initiate Live Clock
setTimeout(startClocks, 100);


// Scroll To Element
window.scrollToTargetAdjusted = function (offset, target, fix = 0){
    var element = document.getElementById(target);
    var headerOffset = offset;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset - fix;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

const urlParams = new URLSearchParams(window.location.search);
const pageSection = urlParams.get('section');
if(pageSection) {
    if(pageSection !== 'aboutSection') {
        scrollToTargetAdjusted(200, pageSection, 0);
    }else{
        scrollToTargetAdjusted(200, pageSection);
    }
}
